const FirstRandText = [
    "Jemmy",
    "Jemoy",
    "Jemuy",
    "Jemby",
    "Jemi",
    "Jemblem",
    "Jemmi",
    "Jemjem",
    "Jamie",
    "Jumping",
    "Juggling",
    "Juicy",
    "Jaded",
    "Jazz",
    "Jet",
    "Jigsaw",
    "Jellyfish",
    "Jiffy",
    "Jokingly",
    "Jumpy",
    "Justified",
    "Junior",
    "Jolted",
    "Jury",
    "Joint",
    "Jaded",
    "Jellybean",
    "Jumbled",
    "Jungle",
    "Jiffy",
    "Jive",
    "Jovial",
    "Joyful",
    "Jinxed",
    "Jumpstart",
    "Justifiable",
    "Jokingly",
    "Jiffy",
    "Jolly"
];
  
const SecondRandText = [
    "Febryan",
    "Febrian",
    "February",
    "Fabrian",
    "Febri",
    "Febry",
    "Februrari",
    "Frogs",
    "Flamingos",
    "Fruit",
    "Foxes",
    "Festival",
    "Fuel",
    "Fix",
    "Funny",
    "Ferret",
    "Fear",
    "Firefighter",
    "Findings",
    "Feelings",
    "Frenzy",
    "Fever",
    "Fusion",
    "Friend",
    "Fireworks",
    "Fortune",
    "Fitness",
    "Force",
    "Flirt",
    "Fellows"
];
  

  export { FirstRandText, SecondRandText }